<template>
  <div class="date-picker">
    <VueDatePickerNext
      v-model:value="dateModel"
      format="DD.MM.YYYY"
      lang="de"
      :disabled="disabled"
      :placeholder="placeholder"
    />
  </div>
</template>

<script>

import { default as VueDatePickerNext } from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import 'vue-datepicker-next/locale/de.es';

export default {
  components: {
    VueDatePickerNext,
  },
  data: function() {
    return {
      dateModel: this.modelValue,
    }
  },
  props: {
    modelValue: {
      type: [Date, null],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Bitte Datum eingeben',
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        this.dateModel = newValue;
      },
      immediate: true,
    },
    dateModel: {
      handler(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
}
</script>

<style>
.mx-input {
  height: 40px;
  border-color: #E4E7ED;
  border-radius: 7px;
  box-shadow: none;
}
.mx-input:disabled {
  color: var(--text-color);
}
.mx-datepicker-popup {
  box-shadow: var(--std-box-shadow);
}
.mx-datepicker-sidebar {
  width: 150px;
}
.mx-datepicker-sidebar+.mx-datepicker-content {
  margin-left: 150px;
}
/*
.mx-btn-shortcut {
  height: 34px;
  border-radius: 7px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 11px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  background: var(--c-button-blue);
  border: 1px solid var(--c-button-blue);
  color: #fff;
  height: 44px;
  margin: 0px 0px 10px 15px;
}
.mx-btn-shortcut:first-child {
  margin-top: 20px;
}
.mx-btn-shortcut:last-child {
  margin-bottom: 0;
}
.mx-btn-shortcut:hover {
  background: var(--c-hover-blue);
  color: #fff;
} */
</style>
